.public-header {
  background: #fff;
  min-height: 4vh;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.top-bar {
  width: 100%;
  position: fixed;
  z-index: 10000;
}
.head_logo {
  width: 200px;
  height: 40px;
}
.head_logo_link {
  align-items: center;
  justify-content: center;
}
.headerMob {
  display: flex;
  background: rgb(243, 232, 238);
  justify-content: center;
  padding: 0 40px;
  height: 50px;
  border: 0 solid #e0e0e0;
  border-bottom-width: 1px;
}
.header {
  display: flex;
  background: linear-gradient(#fff, #fff);
  justify-content: center;
  align-items: center;
  padding: 0px 40px 10px 40px;
  border: 0 solid #e0e0e0;
  border-bottom-width: 1px;
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.logo {
  width: 45px;
  height: 45px;
}
.nav-options {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 20px;
  list-style-type: none;
  color: #282c34;
}
.mobile-option {
  display: none;
}
.option {
  outline-width: 0 !important;
  border: 0 solid #fff !important;
  padding: 2px;
  text-decoration: none;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #442b48 !important;
}
.hide-link {
  outline-width: 0 !important;
  border: 0 solid #fff !important;
  text-decoration: none;
}
.option :hover {
  color: #e74a69;
  border: 0 solid #e74a69 !important;
  border-bottom-width: 2px !important;
}
.mobile-menu {
  display: none;
}
.option-link {
  border: 0 solid #4b4b4b !important;
  border-bottom-width: 0 !important;
}
@media (max-width: 648px) {
  .header {
    padding: 0 10px;
  }
  .headerMob {
    padding: 0 10px;
  }
  .headerMob {
    height: 400px;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  .head_logo {
    width: 160px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: rgb(243, 232, 238);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
  }
  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
  .option-link {
    border: 0 solid #4b4b4b !important;
    border-bottom-width: 1px !important;
  }
  .fas {
    color: #4b4b4b;
  }
  .close-btn {
    font-size: 30px;
  }
  .mobile-menu {
    display: block;
  }
}
