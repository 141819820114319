* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  overflow-x: hidden;
}
.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.App-Main-Sub-Sub-Sub-Header,
.App-Main-Sub-Sub-Sub-Sub-Header,
.App-Main-Sub-Sub-Sub-Sub-Sub-Header,
.footer-link {
  font-family: "Open Sans", sans-serif !important;
}
.App-Main-Sub-Sub-Sub-Header {
  line-height: 1.6 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #4e4e4e !important;
}
.App,
.general-button-p,
.general-h2,
.general-h2h,
.general-p,
.general-pp,
.top-p {
  text-align: center;
}
.footer-link {
  color: #fe7592 !important;
  font-weight: 700 !important;
}
.momercy-ad-img {
  width: 100%;
  height: 100%;
  border-radius: 50px !important;
}
.momercy-ad-img-en {
  width: 100%;
  height: 100%;
  border-radius: 5px !important;
}
.App-Main-Sub-Sub-Sub-Sub-Header {
  line-height: 1.6 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #8a47dd !important;
}
.App-Main-Sub-Sub-Sub-Sub-Sub-Header {
  line-height: 1.6 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #333 !important;
}
.App-Main-Outline {
  outline-width: 0 !important;
  text-decoration: none !important;
}
.font-merriweather,
.general-h2,
.general-h2h,
.general-p {
  font-family: Merriweather, serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Button-Hover:hover {
  color: #cf0e8c !important;
}
.MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}
.general-h2 {
  color: #442b48;
  font-size: 26px;
}
.general-h2link {
  color: #442b48;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
}
.general-h2h {
  color: #643368;
  font-size: 2rem;
  line-height: 1.4;
}
.general-h4h {
  color: #442b48;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 10px;
}
.general-p {
  color: #000;
  font-size: 20px;
}
.textinput {
  padding: 10px;
  margin-bottom: 10px;
  border-color: #a4a4ff;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-family: Merriweather, serif;
  color: #13404f;
}
.general-pp {
  font-family: Lora, serif;
  color: #646464;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 1px;
}
.general-h3,
.general-h33 {
  font-family: Merriweather, serif;
}
.Button-18-active,
.Color-Blue-1 {
  text-transform: capitalize !important;
}
.general-h33 {
  color: #13404f;
  font-size: 1.5rem;
  line-height: 1.32;
}
.general-h3 {
  color: #416165;
  font-size: 18px;
}
.general-p1 {
  font-family: Lora, serif;
  color: #442b48;
  line-height: 1.4;
  font-size: 20px;
}
.general-button-p,
.general-p2 {
  font-family: Merriweather, serif;
}
.general-p2 {
  color: #56584b;
  font-size: 14px;
}
.general-button-p {
  font-weight: 900;
  color: #000;
  font-size: 1.1rem;
}
.colorfff {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.Button-18-active,
.colorfff,
.colorfffonly {
  color: #fff !important;
}
.Button-18 {
  font-size: 18px !important;
}
.Button-18-active {
  font-size: 18px !important;
  background-color: #a4a4ff !important;
}
.Color-Blue-1 {
  background-color: #fff !important;
  color: #0f68bb !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}
.top-page-gradient-background-3 {
  background: linear-gradient(to bottom, #cfe2f3, #f7f9f9);
}
.top-page-gradient-background-2 {
  background: linear-gradient(to bottom, #fff2cc, #f7f9f9);
}
.top-page-gradient-background-1 {
  background: linear-gradient(to bottom, #d9ead3, #f7f9f9);
}
.top-page-gradient-background-0 {
  background: linear-gradient(to bottom, #d9d2e9, #f7f9f9);
}
.gradient-background-to-bottom {
  background: linear-gradient(to bottom, #fe7592, #a4a4ff);
}
.gradient-background {
  background: linear-gradient(to right, #fe7592, #a4a4ff);
}
.public-footer {
  width: 100%;
  background: #f7f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 0 solid #e0e0e0;
  border-top-width: 1px;
}
.shadow-area,
.shadow-area-now {
  padding: 20px;
  border-radius: 10px;
}
.shadow-area {
  margin: 20px !important;
  box-shadow: 1px 2px 4px #b8b8b8;
}
.shadow-area-now {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.right-border {
  padding: 5px;
  border-right: 2px dotted #e0e0e0 !important;
}
.padding-bottom-40 {
  padding-bottom: 20px;
}
.top-p {
  font-size: 40px !important;
  color: #fff;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.8px;
}
.color-def-text {
  color: #282c34;
}
.general-area {
  padding-top: 20px;
  margin-bottom: 0;
  background-color: #f7f9f9;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  margin-top: 150px;
}
.accordion_custom {
  box-shadow: "1px 2px 4px #b8b8b8" !important;
  margin: 5px 5px 10px !important;
  padding: 10px !important;
}
@media (max-width: 648px) {
  .accordion_custom {
    padding: 0px !important;
  }
  .general-h2link {
    font-size: 18px !important;
  }
  .general-h2 {
    font-size: 20px;
  }
  .Page-Top-Area {
    height: auto !important;
    min-height: 350px !important;
  }
  .general-area {
    margin-bottom: 0;
  }
  .colora4a4ff,
  .colorfff {
    font-weight: 500 !important;
    font-size: 18px !important;
  }
  .momercy-ad-img {
    border-radius: 20px !important;
  }
  .top-p {
    font-size: 30px;
  }
  .max-width-280 {
    max-width: 280px !important;
  }
  .height-20vw {
    height: 60vw !important;
  }
  .mui_label_padding_top {
    padding-top: 7px !important;
  }
}
.slick-next,
.slick-prev {
  z-index: 1;
  width: 60px !important;
  height: 60px !important;
}
.slick-prev {
  left: 12% !important;
}
.slick-next {
  right: 12% !important;
}
.mainRoot {
  flex-grow: 1;
  padding-top: 105px;
  background-color: "#f7f9f9";
}
.Article-Head_Image {
  max-height: 400px;
}
@media (max-width: 1640px) {
  .slick-prev {
    left: 5% !important;
  }
  .slick-next {
    right: 5% !important;
  }
}
@media (max-width: 1440px) {
  .general-area {
    margin-bottom: 0;
  }
  .slick-prev {
    left: 2% !important;
  }
  .slick-next {
    right: 2% !important;
  }
}
@media (max-width: 900px) {
  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    right: 0 !important;
  }
  .slick-track {
    max-height: 500px !important;
  }
  .mainRoot {
    padding-top: 0px;
  }
  .Article-Head_Image {
    max-width: 400px !important;
  }
  .general-p {
    font-size: 18px;
  }
}
.dotted_line {
  border: none;
  border-top: 1px dotted #646464;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
}
.MuiAccordionSummary-content {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.carHorizontalRoot2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
}
.carHorizontalDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
}
.carHorizontalContent {
  flex: 1 0 auto;
}
.carHorizontalCover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 120px;
  height: 120px;
}
.carHorizontalCover2 {
  width: 320px;
  height: 220px;
}
