body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9f9;
}

.popup-overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.popup-content {
  background-color: #fff;
  max-width: 30vw;
  margin-top: 100px;
  position: relative;
  width: 90%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 900px) {
  .popup-content {
    max-width: 400px;
  }
}
.popup-content h2 {
  color: #f15a24;
  margin-bottom: 0px;
}

.popup-content p {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-image {
  width: 100%;
  object-fit: contain;
  border-radius: 10px 10px 0 0;
  margin-bottom: 5px;
}

.popup-image2 {
  width: 100%;
  object-fit: contain;
  border-radius: 10px 10px 0 0;
  max-height: 50vh;
}
.shop-now-btn {
  display: inline-block;
  padding: 12px 25px;
  background-color: #f15a24;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.shop-now-btn:hover {
  background-color: #d1491a;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
}

.show-popup {
  visibility: visible;
  opacity: 1;
}
