@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
.article-head {
  color: #333 !important;
  font-family: Montserrat, sans-serif !important;
  line-height: 1.6 !important;
  font-weight: 700 !important;
  font-size: 34px !important;
}
.article-head1 {
  color: #442b48;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 10px;
}
.article-head3 {
  color: #442b48;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
}
.article-head-name {
  color: #442b48;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-weight: 800;
  font-size: 18px;
}
.article-head4 {
  color: #7473e1;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-weight: 700;
  font-size: 18px;
}
.article-p {
  color: #333;
  font-family: "Merriweather", sans-serif;
  line-height: 1.8;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 15px !important;
}
.article-p1 {
  color: #333;
  font-family: "Merriweather", sans-serif;
  line-height: 1.8;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 15px !important;
}
@media (max-width: 648px) {
  .article-head-name {
    font-size: 16px;
  }
  .article-head {
    font-weight: 600 !important;
    font-size: 28px !important;
  }
  .article-head1 {
    font-weight: 800;
    font-size: 24px;
  }
  .article-head3 {
    font-weight: 700;
    font-size: 22px;
  }
  .article-head4 {
    font-weight: 600;
    font-size: 16px;
  }
  .article-p {
    font-size: 16px;
    margin-bottom: 10px !important;
  }
  .article-p1 {
    font-size: 10px;
  }
}
